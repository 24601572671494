import * as React from 'react';
import Layout from '../components/layout';
import '../styles/global.css';


const IndexPage = () => {
    return (
        <main className='main'>
            <Layout>
                <p>test</p>
                <p>test</p>
                <p>test</p>
                <p>test</p>
                <p>test</p>
                <p>test</p>
                <p>test</p>
                <p>test</p>
                <p>test</p>
                <p>test</p>
                <p>test</p>
            </Layout>
        </main>
    )
}

export default IndexPage